@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
::-webkit-scrollbar-thumb {
    background-color: black;
}
::-webkit-scrollbar-track {
    background-color: #ffadec;
}
::-webkit-scrollbar-corner {
    background-color: green;
}
::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.5rem;
}

.hero,
.footer {
    background-color: #21d4fd;
    background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);

    /* background-color: #0093e9;
  background-image: linear-gradient(-45deg, #0093e9 0%, #4c1cbd 100%); */
    animation: gradient 10s ease infinite;
    background-size: 400% 400%;
}

.flying-contact {
    background-color: #ffadec;
    background-image: linear-gradient(62deg, #ffadec 0%, #f3e2fb 100%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    filter: drop-shadow(5px 3px 5px rgba(0, 0, 0, 0.2));
}

@media only screen and (max-width: 768px) {
    .scroll-down-arrow {
        display: none;
    }

    .scroll-down-link {
        display: none;
    }
}

.scroll-down-arrow {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
    background-size: contain;
    background-repeat: no-repeat;
}

.scroll-down-link {
    position: absolute;
    z-index: 49;
    cursor: pointer;
    height: 120px;
    width: 80px;
    margin: 0px 0 0 -40px;
    line-height: 60px;
    left: 50%;
    bottom: 30px;
    color: #fff;
    text-align: center;
    font-size: 70px;
    text-decoration: none;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    animation: fade_move_down 2s ease-in-out infinite;
}

@keyframes fade_move_down {
    0% {
        transform: translate(0, -20px);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translate(0, 20px);
        opacity: 0;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.previous-customers {
}
